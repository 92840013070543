import React from "react"
import { Container, Button, Col, Media, Image, Row } from "react-bootstrap"
import TopMenu from "../components/Layout/TopMenu"
import { LoremIpsum } from "react-lorem-ipsum"
import Footer from "../components/Layout/Footer"

const SecondPage = () => (
  <Container fluid className="home-container p-0">
        <TopMenu></TopMenu>
        <Container fluid className="home-slider">
          <Container fluid="xl" className="home-slider-container d-flex flex-column ">
          <LoremIpsum avgWordsPerSentence={7} avgSentencesPerParagraph={6} />
          </Container>
        </Container>

        <Container fluid className="firstcont py-xl-6">
        <Container fluid="xl" className="state-page-container d-flex flex-column ">
        <Row>
              
              <Col lg={{ order: "last" }} md={{ order: "first" }} className="pl-xl-4">
                
                
              </Col>
              <Col lg={{ order: "last" }} md={{ order: "first" }} className="pl-xl-4">
                
              </Col>
        </Row>
        
          </Container>
        </Container>

        <Footer></Footer>
      </Container>
)

export default SecondPage
